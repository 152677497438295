import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";

import Image from "../../image/image";
import Hyphenate from "../../hyphenate/hyphenate";
/* import { pagerFullPageContextAction } from "../../../app-actions"; */

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserPerson extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article className="node node-teaser teaser-person flex-wrap">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        {/* <Link
          onClick={() =>
            this.props.dispatch(
              pagerFullPageContextAction(this.props.pagerFullPage)
            )
          }
          className="flex-wrap"
          to={
            this.props.item.entityUrl
              ? this.props.item.entityUrl.path
              : this.props.item.path.alias
          }
        > */}
        <Image
          data={this.props.item.fieldBildWCaption.entity.fieldMediaImage}
          nodeTitle={this.props.item.title}
        />
        <div className="card theme-1 person-teaser-details">
          <h3 className="person-name">{this.props.item.title}</h3>
          {this.props.item.fieldRolleOderFunktion[0] && (
            <p className="person-role">
              <Hyphenate>
                {this.props.item.fieldRolleOderFunktion[0].entity.name}
              </Hyphenate>
            </p>
          )}

          <div className="person-contact-details">
            {this.props.item.fieldTelefon && (
              <p>
                <strong>
                  <a
                    href={
                      "tel:" +
                      this.props.item.fieldTelefon.replace(/[^0-9+]/, "")
                    }
                  >
                    {this.props.item.fieldTelefon}
                  </a>
                </strong>
              </p>
            )}
            {this.props.item.fieldMail && (
              <p>
                <strong>
                  <a href={"mailto:" + this.props.item.fieldMail}>
                    {this.props.item.fieldMail}
                  </a>
                </strong>
              </p>
            )}
          </div>
        </div>
        {/* </Link> */}
      </article>
    );
  }
}

export const teaserPersonPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  fieldMail: PropTypes.string,
  fieldTelefon: PropTypes.string,
  fieldMobil: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldBeschreibung: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldKontakinformationen: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        fieldContactType: PropTypes.string.isRequired,
        fieldWert: PropTypes.shape({
          processed: PropTypes.string.isRequired,
        }),
      }),
    })
  ),
  fieldRolleOderFunktion: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldBildWCaption: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
});

TeaserPerson.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserPerson));
