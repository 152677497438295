import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";

import ComponentBildSlider from "./component-bild-slider";
import ErrorBoundary from "../../../../error-boundary";
import SimpleImage from "../../../image/simple-image";
import Hyphenate from "../../../hyphenate/hyphenate";

class ParagraphBilderSlider extends Component {
  state = {
    sliderSettings: {
      adaptiveHeight: true,
      arrows: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: false,
      nextArrow: (
        <button aria-label="next">
          <ArrowRight32
            aria-hidden={true}
            width={48}
            height={48}
            viewBox="0 0 32 24"
          />
        </button>
      ),
      prevArrow: (
        <button aria-label="prev">
          <ArrowLeft32
            aria-hidden={true}
            width={48}
            height={48}
            viewBox="0 0 32 24"
          />
        </button>
      ),
    },
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-bilder-slider": true,
      [`format-${this.props.content.fieldFormat}`]: true,
    });

    const singleImage = this.props.content.fieldBilderWCaption.entity;
    const caption = this.props.content.fieldBilderWCaption.defaultCaption
      ? singleImage.fieldCaption
      : this.props.content.fieldBilderWCaption.customCaption;

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldFormat === "full" ? (
          <ErrorBoundary>
            <ComponentBildSlider
              wrapCaption={true}
              bilder={[this.props.content.fieldBilderWCaption]}
              sliderSettings={this.state.sliderSettings}
              globalCaption={this.props.content.fieldBildunterschrift}
            />
          </ErrorBoundary>
        ) : (
          <div className="container">
            <div className="row">
              <div
                className={classNames({
                  "col-12": true,
                  "col-lg-8 offset-lg-2": this.props.content.fieldFormat === "center",
                  "col-lg-6 col-md-8 offset-md-2 offset-lg-3": this.props.content.fieldFormat === "center-sm",
                  "col-lg-8": this.props.content.fieldFormat === "left",
                  "col-lg-6 col-md-8": this.props.content.fieldFormat === "left-sm",
                  "col-lg-8 offset-lg-4": this.props.content.fieldFormat === "right",
                  "col-lg-6 col-md-8 offset-md-4 offset-lg-6": this.props.content.fieldFormat === "right-sm",
                  "col-12 col-lg-10 offset-lg-1": this.props.content.fieldFormat === "text-aligned",
                })}
              >
                <SimpleImage
                  src={singleImage.fieldMediaImage.style.url}
                  alt={singleImage.fieldMediaImage.alt}
                  credit={singleImage.fieldCredit}
                />
                {/* <ErrorBoundary>
                  <ComponentBildSlider
                    bilder={[this.props.content.fieldBilderWCaption]}
                    sliderSettings={this.state.sliderSettings}
                    globalCaption={this.props.content.fieldBildunterschrift}
                  />
                </ErrorBoundary> */}
              </div>
            </div>

            {caption && (
              <div className="row">
                <div
                  className={classNames({
                    "col-12": true,
                    "col-lg-8 offset-lg-2": this.props.content.fieldFormat === "center",
                    "col-lg-6 col-md-8 offset-md-2 offset-lg-3": this.props.content.fieldFormat === "center-sm",
                    "col-lg-8": this.props.content.fieldFormat === "left",
                    "col-lg-6 col-md-8": this.props.content.fieldFormat === "left-sm",
                    "col-lg-8 offset-lg-4": this.props.content.fieldFormat === "right",
                    "col-lg-6 col-md-8 offset-md-4 offset-lg-6": this.props.content.fieldFormat === "right-sm",
                    "col-12 col-lg-10 offset-lg-1": this.props.content.fieldFormat === "text-aligned",
                  })}
                >
                  <p className="caption small">
                    <Hyphenate>{caption}</Hyphenate>
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    );
  }
}

ParagraphBilderSlider.propTypes = {
  content: PropTypes.shape({
    fieldBildunterschrift: PropTypes.string,
    fieldBilderWCaption: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
          }),
        }),
      })
    ),
    fieldFormat: PropTypes.oneOf(["center", "left", "right", "full"]),
  }),
};

export default ParagraphBilderSlider;
