import React from "react";
import PropTypes from "prop-types";
import SimpleImage from "../../general-components/image/simple-image";
import Hyphenate from "../../general-components/hyphenate/hyphenate";

const FellowIntro = ({
  title,
  teaserImage,
  teaserText,
  theme,
  tags,
  institution,
  subject,
}) => {
  return (
    <section
      className={`paragraph paragraph-simple-intro variant-a intro-fellow ${
        theme || "theme-2-light"
      }`}
    >
      <div className="content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-10 offset-1 col-md-4 offset-md-0 offset-lg-1">
              <div className="image-wrapper">
                <SimpleImage
                  src={teaserImage?.style.url}
                  alt={teaserImage?.alt}
                  credit={teaserImage?.credit}
                />
                <div className="tags-wrapper">
                  {!!tags?.length &&
                    tags.map((tag, i) => (
                      <span key={i} className="tag box">
                        {tag.entity.name}
                      </span>
                    ))}
                </div>
              </div>
              <div className="caption-wrap">
                <Hyphenate>{teaserImage?.caption}</Hyphenate>
              </div>
            </div>
            <div className="col-12 offset-md-1 col-md-5 text-wrapper">
              <h1 className="headline-l">{title}</h1>
              <div className="details">
                {institution && (
                  <p className="institution">
                    <Hyphenate>{institution}</Hyphenate>
                  </p>
                )}
                {subject && (
                  <p className="subject">
                    <Hyphenate>{subject}</Hyphenate>
                  </p>
                )}
              </div>
              <Hyphenate htmlString className="fellow-teasertext">
                {teaserText.processed}
              </Hyphenate>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FellowIntro.propTypes = {
  title: PropTypes.string,
  teaserImage: PropTypes.object,
  teaserText: PropTypes.object,
  theme: PropTypes.string,
  tags: PropTypes.array,
  institution: PropTypes.string,
  subject: PropTypes.string,
};

export default FellowIntro;
