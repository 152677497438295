import React from "react";
import PropTypes from "prop-types";
import Hyphenate from "../../../hyphenate/hyphenate";

const ParagraphHighlightedText = ({ content }) => {
  if (!content.fieldText?.processed) return;

  return (
    <section className="paragraph paragraph-highlighted-text">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-2">
            <div className="card grey info-box">
              <Hyphenate htmlString className="inner-wrapper text">
                {content.fieldText.processed}
              </Hyphenate>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
ParagraphHighlightedText.propTypes = {
  content: PropTypes.shape({
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }),
  }),
};

export default ParagraphHighlightedText;
