import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Link from "../../../link/link";
import { encodeUrl } from "../../../../lib/encode-url";
import Hyphenate from "../../../hyphenate/hyphenate";

/**
 * A module representing a hero-text paragraph.
 */
const ParagraphHeroText = (props) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-hero-text": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className={`card card-l ${props.content.fieldTheme || "theme-1-light"}`}
            >
              {props.content.fieldTitle && (
                <h2 className="headline-xl" id={encodeUrl(props.content.fieldTitle)}>
                  <Hyphenate>{props.content.fieldTitle}</Hyphenate>
                </h2>
              )}
              <Hyphenate htmlString className="text big">{props.content.fieldText.processed}</Hyphenate>
              {props.content.fieldLink?.title && (
                <Link link={props.content.fieldLink} className="hero-link" />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphHeroText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldText: PropTypes.shape({
      processed: PropTypes.string.isRequired,
    }),
    fieldHint: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldTheme: PropTypes.oneOf([
      "theme-1",
      "theme-1-light",
      "theme-2",
      "theme-2-light",
    ]),
    fieldTitle: PropTypes.string,
    fieldLink: PropTypes.object,
  }).isRequired,
};

export default ParagraphHeroText;
