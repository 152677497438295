import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";

const ParagraphEventRegistration = ({ content }) => {
  const actionUrl = content.fieldBrevoFormUrl + "?isAjax=1";
  const newsletterListId = 2;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [error, setError] = useState({
    firstname: false,
    lastname: false,
    email: false,
    optIn: false,
  });

  const intl = useIntl();

  const submitHandler = (e) => {
    e.preventDefault();

    const bodyRaw = {
      firstname: e.target.firstname.value,
      lastname: e.target.lastname.value,
      email: e.target.email.value,
      list: e.target.newsletter.checked && newsletterListId,
      optIn: e.target.optin.checked,
    };

    let errors = false;

    if (bodyRaw.email === "") {
      errors = true;
      setError((prev) => ({
        ...prev,
        email: { messageId: "form.error.empty_field" },
      }));
    }

    if (bodyRaw.firstname === "") {
      errors = true;
      setError((prev) => ({
        ...prev,
        firstname: { messageId: "form.error.empty_field" },
      }));
    }

    if (bodyRaw.lastname === "") {
      errors = true;
      setError((prev) => ({
        ...prev,
        lastname: { messageId: "form.error.empty_field" },
      }));
    }

    if (!bodyRaw.optIn) {
      errors = true;
      setError((prev) => ({
        ...prev,
        optIn: { messageId: "form.error.missing_confirmation" },
      }));
    }

    if (errors) return;

    setLoading(true);

    const formData = new FormData();
    formData.append("VORNAME", bodyRaw.firstname);
    formData.append("NACHNAME", bodyRaw.lastname);
    formData.append("EMAIL", bodyRaw.email);
    formData.append("lists_32[]", bodyRaw.list);
    formData.append("OPT_IN", bodyRaw.optIn);

    fetch(actionUrl, {
      method: "post",
      body: formData,
    })
      .catch((err) => {
        console.error(err);
      })
      .then((data) => {
        data.json().then((body) => {
          body.success && setSuccess(true);
          !body.success &&
            setError((prev) => ({
              ...prev,
              email: { messageId: "form.error.invalid" },
            }));
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <section className="paragraph paragraph-event-registration">
      <div className="container">
        <div id="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
            {content.fieldTitle && (
              <h2 className="headline-l">{content.fieldTitle}</h2>
            )}
            {content.fieldDescription && (
              <div className="text">
                <p>{content.fieldDescription}</p>
              </div>
            )}
            {!success ? (
              <form onSubmit={submitHandler}>
                <div className="form-group">
                  <input
                    type="text"
                    id="VORNAME"
                    name="firstname"
                    autoComplete="off"
                    placeholder={intl.formatMessage({ id: "form.firstname" })}
                    data-required="true"
                    onFocus={() =>
                      setError((prev) => ({ ...prev, firstname: false }))
                    }
                  />
                  {error.firstname && (
                    <div className="error small">
                      <FormattedMessage id={error.firstname.messageId} />
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    id="NACHNAME"
                    name="lastname"
                    autoComplete="off"
                    placeholder={intl.formatMessage({ id: "form.lastname" })}
                    data-required="true"
                    onFocus={() =>
                      setError((prev) => ({ ...prev, lastname: false }))
                    }
                  />
                  {error.lastname && (
                    <div className="error small">
                      <FormattedMessage id={error.lastname.messageId} />
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    id="EMAIL"
                    name="email"
                    autoComplete="off"
                    placeholder={intl.formatMessage({ id: "form.email" })}
                    data-required="true"
                    onFocus={() =>
                      setError((prev) => ({ ...prev, email: false }))
                    }
                  />
                  {error.email && (
                    <div className="error small">
                      <FormattedMessage id={error.email.messageId} />
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      value="2"
                      id="list_32[]"
                      name="newsletter"
                    />
                    <span>
                      <p>
                        <FormattedMessage id="form.newsletter_registration" />
                      </p>
                    </span>
                  </label>

                  <label className="small label-sub">
                    <FormattedMessage id="form.newsletter_cancel_registration" />
                  </label>
                </div>

                <div className="form-group">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      value="1"
                      id="OPT_IN"
                      name="optin"
                      onChange={() =>
                        setError((prev) => ({ ...prev, optIn: false }))
                      }
                    />
                    <span>
                      <p>
                        <FormattedMessage id="form.accept_privacy" />{" "}
                        <Link to={content.fieldLinkPrivacyNotice.url.path}>
                          <FormattedMessage id="form.privacy_policy" />
                        </Link>
                        .
                      </p>
                    </span>
                  </label>

                  {error.optIn && (
                    <div className="error checkbox-error small">
                      <FormattedMessage id={error.optIn.messageId} />
                    </div>
                  )}
                </div>

                <div>
                  <button type="submit" className={classNames({
                    loading: loading,
                    [content.fieldTheme || "theme-grey"]: true,
                  })}>
                    {loading && (
                      <img
                        className="spinner"
                        src="/loader-spinner.svg"
                        alt=""
                      />
                    )}
                    <FormattedMessage id="form.submit" />
                  </button>
                </div>
              </form>
            ) : (
              <div className="message-success">
                <img src="/icon-success.svg" alt="Success" />
                <FormattedMessage id="form.success.event_registration" />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphEventRegistration.propTypes = {
  content: PropTypes.shape({
    fieldTitle: PropTypes.string,
    fieldDescription: PropTypes.string,
    fieldBrevoFormUrl: PropTypes.string.isRequired,
    fieldLinkPrivacyNotice: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      })
    }).isRequired,
    fieldTheme: PropTypes.string,
  }),
};

export default ParagraphEventRegistration;
