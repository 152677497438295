import React from "react";
import DownloadIcon from "./download-icon";
import PropTypes from "prop-types";
import classNames from "classnames";
import SimpleImage from "../../../../image/simple-image";
import Hyphenate from "../../../../hyphenate/hyphenate";

const Download = ({ content }) => {
  return (
    <a
      href={content.file.fieldMediaFile?.entity.url}
      target="_blank"
      rel="noreferrer"
    >
      <div
        className={classNames({
          "card card-download hover": true,
          "theme-1":
            content.theme === "theme-1" || content.theme === "theme-1-light",
          "theme-2":
            content.theme === "theme-2" ||
            content.theme === "theme-2-light" ||
            !content.theme,
          grey: content.theme === "grey",
        })}
      >
        {content.preview && content.showPreview && (
          <div className="preview-wrapper">
            <SimpleImage
              credit={content.previewCredit}
              src={content.preview.style.url}
              alt={content.preview.alt || ""}
            />
          </div>
        )}
        <div className="text-wrapper">
          <p className="download-title">
            <strong>
              <Hyphenate>{content.title}</Hyphenate>
            </strong>
          </p>
          {content.description && (
            <p className="download-description">
              <Hyphenate>{content.description}</Hyphenate>
            </p>
          )}
        </div>

        <div className="icon-wrapper">
          <DownloadIcon />
        </div>
      </div>
    </a>
  );
};

Download.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    file: PropTypes.object.isRequired,
    preview: PropTypes.object,
    previewCredit: PropTypes.string,
    showPreview: PropTypes.bool,
    theme: PropTypes.oneOf(undefined, "theme-1", "theme-2", "theme-3"),
  }),
};

export default Download;
