import React from "react";
import PropTypes from "prop-types";
import Hyphenate from "../../general-components/hyphenate/hyphenate";

const PromotionProgramIntro = (props) => {
  return (
    <section className="promotion-program-intro">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="inner-wrapper">
              <Hyphenate htmlString wrapper={<h1 />}>
                {props.title}
              </Hyphenate>
              {props.teaserText && (
                <Hyphenate htmlString className="text big">
                  {props.teaserText.processed}
                </Hyphenate>
              )}
              {!!props.facts.length && (
                <div className="facts">
                  {props.facts.map((fact, i) => (
                    <div className="flex-wrapper" key={i}>
                      <div className="fact-key">
                        <strong>
                          <Hyphenate>{fact.entity.fieldTitle}</Hyphenate>
                        </strong>
                      </div>
                      <div className="fact-value">
                        <Hyphenate>{fact.entity.fieldValue}</Hyphenate>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

PromotionProgramIntro.propTypes = {
  title: PropTypes.string,
  teaserText: PropTypes.shape({
    processed: PropTypes.string,
  }),
  facts: PropTypes.array,
};

export default PromotionProgramIntro;
