import React, { useEffect, useRef } from "react";

/**
 * Creates a Ref together with a callback function that is being executed, when clicked outside the ref.
 * @param {Function} callback Function that is being executed on outside click.
 * @param {Boolean} condition Optional condition (e.g. some state) that is being checked, before the callback is executed.
 * @returns {React.Ref}
 */
const useOutsideClickHandler = (callback, condition = true) => {
  const ref = useRef();
  useEffect(() => {
    const outsideClickHandler = ({ target }) => {
      if (ref.current && !ref.current.contains(target) && condition) callback();
    };

    // bind the listener
    document.addEventListener("click", outsideClickHandler, true);

    // unbind the listener (clean up)
    return () => {
      document.removeEventListener("click", outsideClickHandler, true);
    };
  }, [ref, condition]);

  return ref;
};

export default useOutsideClickHandler;
