import React from "react";
import PropTypes from "prop-types";
import { teaserEventPropTypes } from "../../../teaser-base/event/teaser-event";
import DateTime from "../../../teaser-base/event/components/datetime";
import { Link } from "react-router-dom";
import getEncodedIcalUrl from "../../../../lib/get-encoded-ical-url";
import { FormattedMessage } from "react-intl";
import SimpleImage from "../../../image/simple-image";
import { self } from "../../../../config";
import { useSelector } from "react-redux";
import Hyphenate from "../../../hyphenate/hyphenate";

const ParagraphEventSpotlight = (props) => {
  const themeMapping = {
    "theme-1": "theme-1",
    "theme-1-light": "theme-1",
    "theme-2": "theme-2",
    "theme-2-light": "theme-2",
    "theme-grey": "theme-grey",
  };

  const reduxStore = useSelector((store) => ({
    locale: store.i18n.currentLanguage,
  }));

  const eventData = props.content.fieldSpotlightOn?.entity;

  const eventImage = eventData.fieldBild?.entity;

  return (
    <section className="paragraph paragraph-event-spotlight">
      <article className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-xl-5 event-meta">
            {eventData.fieldBild !== null && (
              <div className="image-wrapper">
                <SimpleImage
                  src={eventImage.fieldMediaImage.spotlightStyle.url}
                  alt={eventImage.fieldMediaImage.alt}
                  credit={eventImage.fieldCredit}
                />
              </div>
            )}
            <div className="date-wrapper">
              <DateTime
                data={eventData.fieldDatum}
                detailed={true}
                locale={reduxStore.locale}
              />
            </div>
            <div className="type-wrapper">
              <p className="event-type">
                {eventData.fieldSchlagwort[0]?.entity.name}
              </p>
            </div>
            <div className="location-wrapper">
              <p className="location">{eventData.fieldOrt}</p>
            </div>
          </div>
          <div className="col-12 col-md-8 col-xl-7 text-and-title">
            <a
              className="save-button small"
              href={getEncodedIcalUrl(
                eventData.fieldDatum,
                eventData.fieldOrt,
                eventData.title,
                eventData.fieldText?.processed || eventData.title
              )}
            >
              <FormattedMessage id="event.remember" />
              <img src={self + "/icon-badge.svg"} alt="merken" />
            </a>
            <Link to={eventData.entityUrl.path}>
              <div
                className={`card hover ${
                  props.content.fieldTheme
                    ? themeMapping[props.content.fieldTheme]
                    : "theme-1"
                }`}
              >
                {eventData.entityLabel && (
                  <h3 className="headline-l">
                    <Hyphenate>{eventData.entityLabel}</Hyphenate>
                  </h3>
                )}
                {eventData.fieldText && (
                  <Hyphenate htmlString className="text">
                    {eventData.fieldText.processed}
                  </Hyphenate>
                )}
              </div>
            </Link>
          </div>
        </div>
      </article>
    </section>
  );
};

ParagraphEventSpotlight.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldSpotlightOn: PropTypes.shape({
      entity: PropTypes.shape(teaserEventPropTypes),
    }),
    fieldTheme: PropTypes.string,
  }).isRequired,
};

export default ParagraphEventSpotlight;
