import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import SimpleImage from "../../../image/simple-image";
import DateTime from "../../../teaser-base/event/components/datetime";
import getEncodedIcalUrl from "../../../../lib/get-encoded-ical-url";
import { useSelector } from "react-redux";
import Hyphenate from "../../../hyphenate/hyphenate";

moment.locale("de");

/**
 * Usually the first module on an event page - event intro module with most
 * important information.
 */
const ParagraphCalendarInformation = ({ nodeContent }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-calendar-information": true,
  });

  const { currentLanguage } = useSelector((reduxStore) => ({
    currentLanguage: reduxStore.i18n.currentLanguage,
  }));

  const formattedTime = (() => {
    const timeRaw = moment.utc(nodeContent.fieldDatum.value).local();
    if (timeRaw.format("HHmm") === "0000") return false;

    switch (currentLanguage) {
      case "de":
        return timeRaw.format("HH:mm [Uhr]");
      default:
        return timeRaw.format("hh:mm a");
    }
  })();

  return (
    <section className={sectionClassNames}>
      {nodeContent.fieldBild && (
        <div className="calendar-information-main">
          <div className="image-wrapper">
            <SimpleImage
              src={nodeContent.fieldBild.entity.fieldMediaImage.url}
              alt={nodeContent.fieldBild.entity.fieldMediaImage.alt}
              credit={nodeContent.fieldBild.entity.fieldCredit}
            />
          </div>
        </div>
      )}
      <div className="calendar-information-sub">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={`card text-wrapper theme-1-light`}>
                <div className="card-head">
                  <div className="tags-wrapper">
                    {!!nodeContent.fieldSchlagwort.length &&
                      nodeContent.fieldSchlagwort.map((tag, i) => (
                        <div className="tag" key={i}>
                          {tag.entity.name}
                        </div>
                      ))}
                  </div>
                  <div>
                    <a
                      className="save-button small"
                      href={getEncodedIcalUrl(
                        nodeContent.fieldDatum,
                        nodeContent.fieldOrt,
                        nodeContent.title,
                        nodeContent.title
                      )}
                    >
                      <FormattedMessage id="event.remember" />
                      <img src="/icon-badge.svg" alt="merken" />
                    </a>
                  </div>
                </div>
                <h1 className="headline-xl">
                  <Hyphenate>{nodeContent.title}</Hyphenate>
                </h1>
                <div className="event-details">
                  <DateTime
                    data={nodeContent.fieldDatum}
                    detailed={false}
                    locale={currentLanguage}
                  />
                  <div className="date-location">
                    <p className="small">{formattedTime && formattedTime}</p>
                    <p className="small">{nodeContent.fieldOrt}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphCalendarInformation.propTypes = {
  content: PropTypes.object,
  nodeContent: PropTypes.shape({
    title: PropTypes.string,
    fieldDauerAusblenden: PropTypes.bool,
    fieldDatum: PropTypes.shape({
      value: PropTypes.string,
      endValue: PropTypes.string,
    }),
    fieldOrt: PropTypes.string,
    fieldBild: PropTypes.object,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          name: PropTypes.string,
        }),
      })
    ),
  }),
};

export default ParagraphCalendarInformation;
