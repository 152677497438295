import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import Image from "../../../image/image";

/**
 * This get called from ParagraphBilderSlider and actually renders the images.
 */
const ComponentBildSlider = (props) => (
  <>
    {props.bilder.length > 1 ? (
      <Slider {...props.sliderSettings}>
        {props.bilder.map((item, index) => (
          <article key={index} className="media-image">
            <Image
              data={item.entity.fieldMediaImage}
              credit={item.entity.fieldCredit}
              caption={
                item.defaultCaption === false ? item.customCaption : null
              }
              globalCaption={props.globalCaption}
              wrapCaption={props.wrapCaption}
            />
          </article>
        ))}
      </Slider>
    ) : (
      <article className="media-image">
        <Image
          data={props.bilder[0].entity.fieldMediaImage}
          credit={props.bilder[0].entity.fieldCredit}
          caption={
            props.bilder[0].defaultCaption === false
              ? props.bilder[0].customCaption
              : null
          }
          globalCaption={props.globalCaption}
          wrapCaption={props.wrapCaption}
        />
      </article>
    )}
  </>
);

ComponentBildSlider.propTypes = {
  bilder: PropTypes.arrayOf(
    PropTypes.shape({
      defaultCaption: PropTypes.bool,
      customCaption: PropTypes.string,
      entity: PropTypes.shape({
        fieldCredit: PropTypes.string,
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    })
  ),
  globalCaption: PropTypes.string,
  sliderSettings: PropTypes.object.isRequired,
  wrapCaption: PropTypes.bool,
};

export default ComponentBildSlider;
