import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Hyphenate from "../../../../hyphenate/hyphenate";

const Appointment = ({ content }) => {
  const currentLanguage = useSelector(
    (reduxStore) => reduxStore.i18n.currentLanguage
  );

  let formattedDate;
  if (content.fieldDate?.date) {
    formattedDate = (() => {
      const dateRaw = moment.utc(content.fieldDate.date);
      switch (currentLanguage) {
        case "de":
          return dateRaw.format("DD. MMMM YYYY");
        default:
          return dateRaw.format("MMMM DD, YYYY");
      }
    })();
  }

  return (
    <div>
      <p className="date">
        {formattedDate
          ? formattedDate
          : content.fieldSeason && content.fieldSeason}
      </p>
      <p className="title">
        <Hyphenate>{content.title}</Hyphenate>
      </p>
    </div>
  );
};

Appointment.propTypes = {
  content: PropTypes.shape({
    fieldDate: PropTypes.object,
    fieldSeason: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default Appointment;
