import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Hyphenate from "../../../hyphenate/hyphenate";

class ParagraphPageTitle extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-page-title": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16 col-md-10">
              <h1>
                <Hyphenate>{this.props.content.fieldHeading}</Hyphenate>
              </h1>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphPageTitle.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
  }),
};

export default ParagraphPageTitle;
