import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Social = (props) => {
  return (
    <ul>
      {props.footerConfig.fieldLinkedin && (
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldLinkedin.url.path}
          >
            LinkedIn
          </a>
        </li>
      )}
      {props.footerConfig.fieldFacebook && (
        <li>
          <a
            className="facebook"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldFacebook.url.path}
          >
            Facebook
          </a>
        </li>
      )}
      {props.footerConfig.fieldYoutube && (
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldYoutube.url.path}
          >
            YouTube
          </a>
        </li>
      )}
      {props.footerConfig.fieldFlickr && (
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldFlickr.url.path}
          >
            Flickr
          </a>
        </li>
      )}
      {props.footerConfig.fieldInstagram && (
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldInstagram.url.path}
          >
            Instagram
          </a>
        </li>
      )}
      {props.footerConfig.fieldVimeo && (
        <li>
          <a
            className="vimeo"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldVimeo.url.path}
          >
            Vimeo
          </a>
        </li>
      )}
      {props.footerConfig.fieldPinterest && (
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldPinterest.url.path}
          >
            Pinterest
          </a>
        </li>
      )}
      {props.footerConfig.fieldTwitter && (
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldTwitter.url.path}
          >
            Twitter
          </a>
        </li>
      )}
      {props.footerConfig.fieldXing && (
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldXing.url.path}
          >
            Xing
          </a>
        </li>
      )}
      {props.footerConfig.fieldTumblr && (
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldTumblr.url.path}
          >
            Tumblr
          </a>
        </li>
      )}
      {props.footerConfig.fieldBluesky && (
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldBluesky.url.path}
          >
            Bluesky
          </a>
        </li>
      )}
      {props.footerConfig.fieldLinkNewsletter && (
        <li>
          <Link
            to={
              props.lang === "de"
                ? props.footerConfig.fieldLinkNewsletter.url.path
                : props.footerConfig.fieldLinkNewsletter.url.translated.path
            }
          >
            Newsletter
          </Link>
        </li>
      )}
    </ul>
  );
};

const SocialElementPropTypes = PropTypes.shape({
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
});

Social.propTypes = {
  footerConfig: PropTypes.shape({
    fieldLinkedin: SocialElementPropTypes,
    fieldFacebook: SocialElementPropTypes,
    fieldYoutube: SocialElementPropTypes,
    fieldFlickr: SocialElementPropTypes,
    fieldInstagram: SocialElementPropTypes,
    fieldVimeo: SocialElementPropTypes,
    fieldPinterest: SocialElementPropTypes,
    fieldTwitter: SocialElementPropTypes,
    fieldXing: SocialElementPropTypes,
    fieldTumblr: SocialElementPropTypes,
    fieldLinkNewsletter: SocialElementPropTypes,
  }),
};

export default Social;
