import React from "react";
import PropTypes from "prop-types";
import SimpleImage from "../../image/simple-image";
import IconPlay from "./components/icon-play";
import { encodeUrl } from "../../../lib/encode-url";
import Hyphenate from "../../hyphenate/hyphenate";

const TeaserMedia = ({ item, ...props }) => {
  const themeMapping = {
    "theme-1": "theme-1-light",
    "theme-1-light": "theme-1-light",
    "theme-2": "theme-2-light",
    "theme-2-light": "theme-2-light",
    "theme-grey": "theme-grey",
  };

  const mediumId = encodeUrl(item.title);

  const thumbnail =
    item.fieldBild?.entity.fieldMediaImage ||
    item.fieldVideoFileMedia?.entity.fieldCustomThumbnail?.entity.fieldMediaImage ||
    item.fieldVideoFileMedia?.entity.thumbnail;


  return (
    <>
      <article
        className="node node-teaser teaser-media"
        onClick={() => props.onClick(mediumId)}
      >
        {thumbnail && (
          <div className="image-wrapper">
            <SimpleImage src={thumbnail.style.url} alt={thumbnail.alt} />
            <div className="icon-wrapper">
              <IconPlay
                theme={
                  item.fieldTheme
                    ? themeMapping[item.fieldTheme]
                    : "theme-2-light"
                }
              />
            </div>
          </div>
        )}
        <div
          className={`card hover ${
            item.fieldTheme ? themeMapping[item.fieldTheme] : "theme-2-light"
          }`}
        >
          <h4>{item.title}</h4>
          {item.fieldTeasertext && (
            <Hyphenate htmlString className="text">{item.fieldTeasertext.processed}</Hyphenate>
          )}
        </div>
      </article>
    </>
  );
};

export const teaserMediaPropTypes = {
  title: PropTypes.string,
  fieldTeasertext: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldVideoUrl: PropTypes.string,
  fieldVideo: PropTypes.shape({
    entity: PropTypes.object,
  }),
  fieldBild: PropTypes.shape({
    entity: PropTypes.shape({
      name: PropTypes.string,
      fieldBildunterschrift: PropTypes.string,
      fieldCredit: PropTypes.string,
      fieldMediaImage: PropTypes.shape({
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
        alt: PropTypes.string,
      }),
    }),
  }),
};

TeaserMedia.propTypes = {
  item: PropTypes.shape(teaserMediaPropTypes),
  onClick: PropTypes.func,
};

export default TeaserMedia;
