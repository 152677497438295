import PropTypes from "prop-types";
import React from "react";
import Hyphenate from "../../../hyphenate/hyphenate";

const ParagraphList = ({ content }) => {
  const listElements = content.fieldListElements.map((element) => ({
    label: element.entity.fieldLabel,
    value: element.entity.fieldValue,
  }));

  return (
    <section className="paragraph paragraph-list">
      <div className="container">
        <ul>
          {listElements.map((element, i) => (
            <li key={i}>
              {element.label && (
                <p className="label">
                  <strong>{element.label}</strong>
                </p>
              )}
              {element.value && (
                <p className="value">
                  <Hyphenate>{element.value}</Hyphenate>
                </p>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

ParagraphList.propTypes = {
  content: PropTypes.shape({
    fieldListElements: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldLabel: PropTypes.string,
          fieldValue: PropTypes.string,
        }),
      })
    ),
  }),
};

export default ParagraphList;
