import React from "react";
import PropTypes from "prop-types";
import SimpleImage from "../../../image/simple-image";
import Hyphenate from "../../../hyphenate/hyphenate";

const ParagraphBioAdvanced = ({ content }) => {
  const imageData = content.fieldImage.entity;

  return (
    <section className="paragraph paragraph-bio-advanced">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-xl-3">
            <SimpleImage
              src={imageData.fieldMediaImage.style.url}
              alt={imageData.fieldMediaImage.alt}
              credit={imageData.fieldCredit}
              caption={imageData.fieldCaption}
            />
          </div>
          <div className="col-12 col-md-8 col-xl-6">
            <div className="content-wrapper">
              {content.fieldTitle && (
                <h3 className="body-s">
                  <strong>
                    <Hyphenate>{content.fieldTitle}</Hyphenate>
                  </strong>
                </h3>
              )}
              {content.fieldText && (
                <Hyphenate htmlString className="text">
                  {content.fieldText.processed}
                </Hyphenate>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphBioAdvanced.propTypes = {
  content: PropTypes.shape({
    fieldTitle: PropTypes.string,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldImage: PropTypes.object,
  }),
};

export default ParagraphBioAdvanced;
