import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Hyphenate from "../../../hyphenate/hyphenate";

/**
 * A module representing a text paragraph.
 */
class ParagraphText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-text": true,
      "background-color": this.props.content.fieldFarbigHinterlegen,
      big: this.props.content.fieldBig,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div
              className={classNames({
                "col-wrapper col-12 col-lg-10 offset-lg-1": true,
                "col-xl-8": !this.props.content.fieldBig,
              })}
            >
              <Hyphenate
                className={classNames({
                  text: true,
                  big: this.props.content.fieldBig,
                  "two-cols": this.props.content.fieldTwoCols,
                })}
                htmlString
              >
                {this.props.content.fieldText.processed}
              </Hyphenate>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldFarbigHinterlegen: PropTypes.bool,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }).isRequired,
    fieldBig: PropTypes.bool,
    fieldTwoCols: PropTypes.bool,
  }).isRequired,
};

export default ParagraphText;
