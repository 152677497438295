import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { teaserNewsPropTypes } from "../../../teaser-base/news/teaser-news";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";
import getLayoutPatterns from "../../../../lib/get-layout-patterns";
import { Link } from "react-router-dom";
import { self } from "../../../../config";
import Hyphenate from "../../../hyphenate/hyphenate";

const ParagraphDossier = (props) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-dossier": true,
    "with-background-image": props.content.fieldImage !== null,
  });

  let customStyle = {};

  if (props.content.fieldImage !== null) {
    customStyle = {
      background: `url(${props.content.fieldImage.entity.fieldMediaImage.style.url})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
  }

  const items = props.content.fieldModules.map((e) => e.entity);

  return (
    <section className={sectionClassNames}>
      {props.content.fieldImage !== null && (
        <div className="background-image" style={customStyle} />
      )}
      {/* {props.content.fieldHeading && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="headline-xl">{props.content.fieldHeading}</h2>
            </div>
          </div>
        </div>
      )} */}

      <div className="container cards-wrapper">
        <div className="row">
          {getLayoutPatterns(items).map((chunk, i) => (
            <React.Fragment key={i}>
              {chunk.chunk.map((paragraph, j) => (
                <div
                  key={j}
                  className={classNames({
                    "col-12": chunk.pattern[j] === 12,
                    "col-12 col-lg-6": chunk.pattern[j] === 6,
                    "col-12 col-lg-4": chunk.pattern[j] === 4,
                  })}
                  to={paragraph.fieldReferencedContent.entity.entityUrl.path}
                >
                  <Link
                    className={classNames({
                      card: true,
                      "theme-2 primary": chunk.pattern[j] === 4,
                      "theme-2-light secondary": chunk.pattern[j] !== 4,
                    })}
                    to={paragraph.fieldReferencedContent.entity.entityUrl.path}
                  >
                    <div className="headline-wrapper">
                      <Hyphenate
                        htmlString
                        className="headline-l"
                        wrapper={<h3 />}
                      >
                        {paragraph.fieldReferencedContent.entity.title}
                      </Hyphenate>
                      <div className="icon-wrapper">
                        <img src={self + "/icon-link-external.svg"} />
                      </div>
                    </div>
                    {paragraph.fieldText ? (
                      <Hyphenate
                        htmlString
                        className="card-body"
                        wrapper={<div />}
                      >
                        {paragraph.fieldText.processed}
                      </Hyphenate>
                    ) : (
                      paragraph.fieldReferencedContent.entity
                        .fieldTeasertext && (
                        <Hyphenate htmlString className="card-body">
                          {
                            paragraph.fieldReferencedContent.entity
                              .fieldTeasertext.processed
                          }
                        </Hyphenate>
                      )
                    )}
                  </Link>
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphDossier.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
    fieldBeitraegeDossier: PropTypes.arrayOf(
      PropTypes.oneOfType([teaserNewsPropTypes, teaserPersonPropTypes])
    ),
  }),
};

export default ParagraphDossier;
