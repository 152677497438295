import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Hyphenate from "../../../hyphenate/hyphenate";
import classNames from "classnames";

const ParagraphNewsletter = ({ content }) => {
  // the URL where the form data will be send to
  // isAjax=1 is a required parameter, otherwise the request will respond with the form itself
  const actionUrl =
    "https://26409a73.sibforms.com/serve/MUIFALxHpb-Utf9G9WxkdvuyvlZ5kiMg_ek_keLiM8ExjAEGv_guSJwHIO6HiF-Z6lNyIplu1wRxt99zkjQGggLpGeZt0ApnHJNMecua3bLwMpZkUsXX6Ao1Sx23blXfsZsUbOu8Zu1dH8JSmDKZAxAkbi9ZjqmhbSeVhOx69PHCjQZBJw8j7IsKgSM2tzdTwetrw2j50UR3zbtp?isAjax=1";

  const emailRef = useRef();
  const optInRef = useRef();

  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    email: false,
    optIn: false,
  });

  const [success, setSuccess] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();

    if (emailRef.current.value === "") {
      return setError((prev) => ({
        ...prev,
        email: { messageId: "form.error.empty_field" },
      }));
    }

    if (!optInRef.current.checked) {
      return setError((prev) => ({
        ...prev,
        optIn: { messageId: "form.error.missing_confirmation" },
      }));
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("EMAIL", emailRef.current.value);

    fetch(actionUrl, {
      method: "post",
      body: formData,
    })
      .catch((err) => {
        console.error(err);
      })
      .then((data) => {
        data.json().then((body) => {
          body.success && setSuccess(true);
          !body.success &&
            setError((prev) => ({
              ...prev,
              email: { messageId: "form.error.invalid" },
            }));
        });
      })
      .finally(() => setLoading(false));
  };

  const privacyLink = content.fieldLinkPrivacyNotice?.url.routed ? (
    <Link to={content.fieldLinkPrivacyNotice.url.path}>
      <FormattedMessage id="form.privacy_policy" />
    </Link>
  ) : (
    <a href={content.fieldLinkPrivacyNotice?.url.path}>
      <FormattedMessage id="form.privacy_policy" />
    </a>
  );

  return (
    <section className="paragraph paragraph-newsletter">
      <div className="container">
        <div id="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
            {content.fieldHeading && <h2><Hyphenate>{content.fieldHeading}</Hyphenate></h2>}
            {content.fieldText?.processed && (
              <Hyphenate htmlString className="text">
                {content.fieldText.processed}
              </Hyphenate>
            )}
            {!success ? (
              <form onSubmit={submitHandler}>
                <div className="form-group">
                  <input
                    type="text"
                    id="EMAIL"
                    name="EMAIL"
                    autoComplete="off"
                    placeholder={intl.formatMessage({ id: "form.email" })}
                    data-required="true"
                    ref={emailRef}
                    onFocus={() =>
                      setError((prev) => ({ ...prev, email: false }))
                    }
                  />
                  {error.email && (
                    <div className="error small">
                      <FormattedMessage id={error.email.messageId} />
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      value="1"
                      id="OPT_IN"
                      name="OPT_IN"
                      ref={optInRef}
                      onChange={() =>
                        setError((prev) => ({ ...prev, optIn: false }))
                      }
                    />
                    <span>
                      <p>
                        <FormattedMessage id="form.accept_privacy_and_newsletter_registration" />{" "}
                        {privacyLink}.
                      </p>
                    </span>
                  </label>

                  {error.optIn && (
                    <div className="error checkbox-error small">
                      <FormattedMessage id={error.optIn.messageId} />
                    </div>
                  )}

                  <label className="small label-sub">
                    <FormattedMessage id="form.newsletter_cancel_registration" />
                  </label>
                </div>

                <div>
                  <div>
                    <button type="submit" className={classNames({
                      loading: loading,
                      [content.fieldTheme || "theme-grey"]: true,
                    })}>
                      {loading && (
                        <img
                          className="spinner"
                          src="/loader-spinner.svg"
                          alt=""
                        />
                      )}
                      <FormattedMessage id="form.submit" />
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <div className="message-success">
                <img src="/icon-success.svg" alt="Success" />
                <FormattedMessage id="form.success.newsletter" />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphNewsletter.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
      value: PropTypes.string,
    }),
    fieldLinkPrivacyNotice: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldTheme: PropTypes.string,
  }),
};

export default ParagraphNewsletter;
