import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SimpleImage from "../../image/simple-image";
import Hyphenate from "../../hyphenate/hyphenate";

const TeaserFellow = ({ content }) => {
  return (
    <article className="node node-teaser teaser-fellow">
      <Link to={content.entityUrl.path}>
        <div className="image-wrapper">
          {content.fieldTeaserbild && (
            <SimpleImage
              src={content.fieldTeaserbild?.entity.fieldMediaImage.style.url}
              alt={content.fieldTeaserbild?.entity.fieldMediaImage.alt}
              credit={content.fieldTeaserbild?.entity.fieldCredit}
            />
          )}
          {!!content.fieldSchlagwort?.length && (
            <div className="tags-wrapper">
              {content.fieldSchlagwort.map((tag, i) => (
                <span key={i} className="tag box">
                  {tag.entity.name}
                </span>
              ))}
            </div>
          )}
        </div>
        <div className={`card ${content.fieldTheme || "theme-2-light"}`}>
          <h3>{content.title}</h3>
          <div className="details">
            {content.fieldInstitution && (
              <p className="institution">
                <Hyphenate>{content.fieldInstitution.entity.name}</Hyphenate>
              </p>
            )}
            {content.fieldSubject && (
              <p className="subject">
                <Hyphenate>{content.fieldSubject.entity.name}</Hyphenate>
              </p>
            )}
          </div>
        </div>
      </Link>
    </article>
  );
};

TeaserFellow.propTypes = {
  content: PropTypes.object,
};

export default TeaserFellow;
