import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const mapStateToProps = reduxStore => ({ i18n: reduxStore.i18n });

class LanguageSwitch extends Component {
  render() {
    if (this.props.i18n) {
      return (
        <div className="language-switch" onClick={this.props.closeMenu ? this.props.closeMenu : null}>
          <Link
            to={this.props.i18n.currentContentGermanTranslation ? this.props.i18n.currentContentGermanTranslation : '#'}
            className={classNames({
              active: this.props.i18n.currentLanguage === 'de',
              'not-translated': !this.props.i18n.currentContentGermanTranslation
            })}
          >
            DE
          </Link>

          <Link
            to={this.props.i18n.currentContentEnglishTranslation ? this.props.i18n.currentContentEnglishTranslation : '#'}
            className={classNames({
              active: this.props.i18n.currentLanguage === 'en',
              'not-translated': !this.props.i18n.currentContentEnglishTranslation
            })}
          >
            EN
          </Link>
        </div>
      );
    }

    return false;

  }
}

LanguageSwitch.propTypes = {
  i18n: PropTypes.object.isRequired,
  closeMenu: PropTypes.func
};

export default connect(mapStateToProps)(LanguageSwitch);