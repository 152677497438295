import React from "react";
import PropTypes from "prop-types";
import Hyphenate from "../../../hyphenate/hyphenate";

/**
 *
 */
const ParagraphBio = ({ content }) => {
  return (
    <section className="paragraph paragraph-bio">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <div className={`card ${content.fieldTheme || "theme-2-light"}`}>
              <ul className="bio-list">
                {content.fieldBioElements.map((item, i) => (
                  <li key={i} className="bio-list-item">
                    <div className="text-wrapper">
                      <h3>
                        <Hyphenate>{item.entity.fieldLabel}</Hyphenate>
                      </h3>
                      <p>
                        <Hyphenate>{item.entity.fieldValue}</Hyphenate>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphBio.propTypes = {
  content: PropTypes.shape({
    fieldTheme: PropTypes.string,
    fieldBioElements: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldLabel: PropTypes.string.isRequired,
          fieldValue: PropTypes.string.isRequired,
        }),
      })
    ),
  }),
};

export default ParagraphBio;
