import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import personPageQuery from "./person.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { withRouter } from "react-router-dom";
import { i18nActionHelper } from "../i18n/i18n";
import Error404 from "../errors/404";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
  adminApp: reduxStore.appStore.adminApp,
});

class Person extends Component {
  componentDidMount() {
    i18nActionHelper(this.props, this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      i18nActionHelper(this.props, this.props.dispatch);
    }
  }

  render() {
    if (this.props.adminApp) {
      if (this.props.data.route) {
        const published = this.props.data.route.entity.status
          ? "node-published"
          : "node-unpublished";

        return (
          <article
            className={`node-${this.props.data.route.entity.entityBundle} node-${this.props.data.route.entity.entityId} node-full-page ${published}`}
          >
            <Helmet>
              <title>{`WSW | ${this.props.data.route.entity.entityLabel}`}</title>
            </Helmet>

            <ContentBase
              content={this.props.data.route.entity.fieldModules}
              nodeContent={this.props.data.route.entity}
            />

            {this.props.pagerFullPageContextId &&
              this.props.pagerConfig.filter(
                (config) => config.id === this.props.pagerFullPageContextId
              ).length > 0 && (
                <PagerFullPage
                  config={
                    this.props.pagerConfig.filter(
                      (config) =>
                        config.id === this.props.pagerFullPageContextId
                    )[0]
                  }
                  currentId={this.props.data.route.entity.entityId}
                />
              )}
          </article>
        );
      }

      return <LoadingIndicator />;
    }

    return <Error404 />;
  }
}

Person.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default graphql(personPageQuery, {
  options: (props) => ({
    variables: { path: props.location.pathname },
  }),
})(connect(mapStateToProps)(withRouter(Person)));
